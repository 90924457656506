import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.css";
import ContactStrip from "./components/contactStrip";
import Navbar from "./components/navbar";
import Header from "./components/header";
import Content from "./components/content";
import ContactForm from "./components/contactForm";
import Footer from "./components/footer";
import Clients from "./components/clients";
import Galery from "./components/galery";


ReactDOM.render(
  <div>
    <Navbar />
    <Header />
    <Content />
    <Clients />
    <Galery />
    <ContactForm />
    <ContactStrip />
    <Footer />
  </div>,
  document.getElementById("root")
);
serviceWorker.unregister();
 
