import React, { Component } from "react";

import "./css/navbar.css";
class Navbar extends Component {
  state = {
    scrolled: false
  };
  componentDidMount() {
    window.addEventListener("scroll", () => {
      const isTop = window.scrollY < 40;
      if (isTop !== true) {
        this.setState({ scrolled: true });
      } else {
        this.setState({ scrolled: false });
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll");
  }

  render() {
    return (
      <div
        class={
          this.state.scrolled ? "navbar-wapped scrolled" : "navbar-wrapper"
        }
      >
        <nav class="navbar navbar-dark navbar-expand-sm navbar-static-top">
          <a class="navbar-brand ml-lg-5" href="/#">
            <img
              src={require("../assets/img/SAT_logo1.png")}
              width="190"
              height="70"
              alt="Logo"
            ></img>
          </a>


          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item active">
                <a class="nav-link" href="/#">
                  <p>Nosotros </p><span class="sr-only">(current)</span>
                </a>
              </li>
              <li class="nav-item ml-5">
                <a class="nav-link" href="/#services">
                  Servicios
                </a>
              </li>
              <li class="nav-item ml-5">
                <a class="nav-link" href="/#clients">
                  Clientes
                </a>
              </li>
              <li class="nav-item ml-5">
                <a class="nav-link" href="/#galery">
                  Galeria
                </a>
              </li>
              <li class="nav-item ml-5 mr-5">
                <a class="nav-link" href="/#contact">
                  Contacto
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

export default Navbar;
