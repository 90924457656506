import React, { Component } from "react";
import "./css/contactStrip.css";
import {
  FaFacebookSquare,
  FaInstagram,
  FaEnvelope,
  FaPhone
} from "react-icons/fa";

class contactStrip extends Component {
  render() {
    return (
      <div class="stript">
        <div class="container">
          <div class="row">
            <div class="col">
              <center>
                <a href="tel: +52-664-648-82-96" target="blank">
                  <FaPhone class="icon" title="+52-664-648-82-96" />
                </a>
              </center>
            </div>
            <div class="col">
              <center>
                <a
                  href="mailto: contacto@sistemasaudioviusualestijuana.com"
                  target="blank"
                >
                  <FaEnvelope class="icon" />
                </a>
              </center>
            </div>
            <div class="col">
              <center>
                <a
                  href="https://www.facebook.com/SistemasAudiovisualesTijuana/"
                  target="blank"
                >
                  <FaFacebookSquare class="icon" />
                </a>
              </center>
            </div>
            <div class="col">
              <center>
                <a
                  href="https://www.instagram.com/sistemasaudiovisualestijuana/"
                  target="blank"
                >
                  <FaInstagram class="icon" />
                </a>
              </center>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default contactStrip;
