import React, { Component } from "react";
import "./css/header.css";

class Header extends Component {
  render() {
    return (
      <div class="jumbotron jumbotron-fluid">
        <div class="hero-text">
          <h2 class="header2-blue">
            <strong>SERVICIOS AUDIOVISUALES</strong>
          </h2>
          <h2 class="header2-white">
            <strong>PARA TODO TIPO DE EVENTOS</strong>
          </h2>
        </div>
      </div>
    );
  }
}

export default Header;
