import React, { Component } from "react";
import "./css/footer.css";

class Footer extends Component {
    render() {
        return(
            <footer class="page-footer font-small special-color-dark pt-4">
                <div class="container">
                    <center>
                        <div class="row">
                            <div class="col">
                                <p>Designed by:</p>
                                <a href="https://www.behance.net/ivanniaedith-idgd"
                                    target="blank">
                                    <img
                                        class="rounded-circle"
                                        src={require("../assets/img/references/edith.jpg")}
                                        alt = "Cesar"
                                        width = "55"
                                    ></img>
                                </a>
                                
                            </div>
                            <div class="col">
                                <p>Developed by:</p>
                                <a href="https://cesartorres.org/"
                                    target="blank">
                                    <img
                                        class="rouded-circle"
                                        src={require("../assets/img/references/cesar.png")}
                                        alt = "Cesar"
                                        width = "55"
                                    ></img>
                                </a>
                                
                            </div>
                        </div>
                    </center>
                </div>
                <div class="footer-copyright text-center py-3">
                    <h5>© 2020 Copyright:</h5>
                    <a href="https://SistemasAudiovisualesTijuana.com/"> SistemasAudiovisualesTijuana.com</a>
                </div>
            </footer>
        );
    }
}
export default Footer;
