import React, { Component } from "react";
import "./css/contactForm.css";
import * as emailjs from "emailjs-com"


class contactForm extends Component {
  constructor(props) {
    super(props);
    this.state ={
      name: '',
      email: '',
      message: '',
    };
    this.handleChange = this.handleChange.bind(this);
	  this.handleSubmit = this.handleSubmit.bind(this);
  }
  render() {
    return (
      <div class="cForm" id="contact">
        <div class="row">
          <div class="col container mrg">
            <center>
              <div  class="wrapper">
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                
                <h1>
                  <strong>CONTACTE CON NOSOTROS</strong>
                </h1>
                
                <br/>
                <br/>
                <br/>

                <form
                  className="contact_form_class"
                  // method="POST"
                  // name="contact_form"

                  >
                  <input 
                    name="name" 
                    type="text" 
                    required
                    placeholder="Nombre *"
                    // defaultValue="Nombre"
                    onChange={this.handleChange}
                    value={this.state.name}
                    // id="name" 
                  ></input>
                  <br/>
                  <br/>
                  <input
                    type="email"
                    name="email"
                    required
                    placeholder="Correo electronico *"
                    // defaultValue="Correo electronico"
                    onChange={this.handleChange}
                    value={this.state.email}
                    
                    
                    // id="email"
                  ></input>
                  <br/>
                  <br/>
                  <textarea
                    type="text"
                    name="message"
                    required
                    placeholder="Mensaje *"
                    // defaultValue='Mensaje'
                    onChange={this.handleChange}
                    value={this.state.message}
                    
                    
                    // id="message"
                  ></textarea>
                  <br/>
                  <br/>
                  <br/>
                  <input type="button" value="Enviar" className="btn btn--submit" onClick={this.handleSubmit} />
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                </form>
              </div>
            </center>
          </div>
          <div class="col">
            <center>
              <div class="container contact-default">
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <h3>¡Llámanos!</h3>
                <br/>
                <p>(664) 648-8296</p>
                <br/>
                <br/>
                <h3>¡Escríbenos!</h3>
                <br/>
                <p>contacto@sistemasaudiovisualestijuana.com</p>
              </div>
            </center>
          </div>
        </div>
      </div>
    );
  }
  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  };
  handleSubmit = (event) => {
    event.preventDefault();
    if(this.state.email === '' || this.state.name === '' || this.state.message === '')
    {
      window.alert('Todos los campos son requeridos para enviar el mensaje.')
      window.location.reload();
    }
    else {
      emailjs.send(
        "gmail",
        "template_7HeYQshf",
        {message_html: this.state.message, from_name: this.state.name, reply_to: this.state.email},
        "user_5NEi9e45ri9TlAnftxpI2"
      )
      window.alert('Mensaje enviado! Muy pronto nos pondremos en contacto con usted!')
      window.location.reload();
    } 
  }
}

export default contactForm;
