import React, { Component } from "react";
import "./css/clients.css";
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

class Clients extends Component {

    render() {
        return(
            <div class="clients container" id="clients">
            <br/>
            <br/>
            <br/>
                <div>
                    <center>
                        <h1><strong>ALGUNOS DE NUESTROS CLIENTES</strong></h1>
                    </center>
                    <Carousel
                        slidesPerPage={3}
                        autoPlay={3000}
                        animationSpeed={1000}
                        infinite
                        breakpoints={{
                            640: {
                            slidesPerPage: 1,
                            arrows: false
                            },
                            900: {
                            slidesPerPage: 2,
                            arrows: false
                            }
                        }}
                    >
                        <img src={require("../assets/img/clients/1.jpg")}  alt="Ayuntamiento de Rosarito"/>
                        <img src={require("../assets/img/clients/2.jpg")}  alt="CECyTE"/>
                        <img src={require("../assets/img/clients/3.jpg")}  alt="Club de Niños y Niñas Rosarito"/>
                        <img src={require("../assets/img/clients/4.png")}  alt="CoBach"/>
                        <img src={require("../assets/img/clients/5.png")}  alt="Gasmart"/>
                        <img src={require("../assets/img/clients/6.png")}  alt="Gobierno de Baja California"/>
                        <img src={require("../assets/img/clients/7.png")}  alt="Instituto Cumbres Tijuana"/>
                        <img src={require("../assets/img/clients/8.png")}  alt="Jafra"/>
                        <img src={require("../assets/img/clients/9.jpg")}  alt="Sistemas Medicos Alaris"/>
                        <img src={require("../assets/img/clients/10.jpg")} alt="Rosarito Art Fest A.C."/>
                        <img src={require("../assets/img/clients/11.png")} alt="Sistema Educativo Estatal Baja California"/>
                        <img src={require("../assets/img/clients/12.png")} alt="Grupo Tersa"/>
                    </Carousel>
                </div>
            </div>
        );
    }
}

export default Clients
