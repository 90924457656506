import React, { Component } from "react";
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import "./css/galery.css"


class Galery extends Component{
    render(){
        return(
            <div class="galery" id="galery">
                <h1>
                    <center>
                        <strong>Galeria</strong>
                    </center>
                </h1>
                {/* <div class="container"> */}
                <Carousel
                    autoPlay={3000}
                    animationSpeed={1000}
                    infinite
                >
                    <img  src={require("../assets/img/carousel/img1.jpg")} alt="Conciertos"/>
                    <img  src={require("../assets/img/carousel/img2.jpg")} alt="Tarimas"/>
                    <img  src={require("../assets/img/carousel/img3.jpg")} alt="Expos"/>
                    <img  src={require("../assets/img/carousel/img4.jpg")} alt="Grand Souport"/>
                    <img  src={require("../assets/img/carousel/img5.jpg")} alt="Consolas"/>
                    <img  src={require("../assets/img/carousel/img6.jpg")} alt="Talleres"/>
                    <img  src={require("../assets/img/carousel/img7.jpg")} alt="Conciertos"/>
                    <img  src={require("../assets/img/carousel/img8.jpg")} alt="Mixer"/>
                </Carousel>
                {/* </div> */}
            </div>
        );
    }
}

export default Galery;
