import React, { Component } from "react";
import '@brainhubeu/react-carousel/lib/style.css';
import "./css/content.css";
// import { useState } from "react";

class Content extends Component {
  render() {
    return (
      <div class="content">
        {/* Inicia Nosotros */}
        <div class="about-us">
          <div class="us-text">
            <div class="text-wapper">
              
              <h2 id="us-title">+25 AÑOS DE EXPERIENCIA</h2>
              <br/>
              <br/>
              <p>
                Somos una empresa dedicada a la renta de equipos audiovisuales para todo tipo de eventos. 
                Nuestro personal se encuetra capacitado para el control del equipo y llevar acabo tu evento a la perfección. 
                Tenemos más de 1000 eventos en 25 años de experiencia que respaldan a la compañía, nuestro profesionalismo y dedicación.
                Para llevar acabo todo tipo de eventos en la zona de Baja California.
              </p>
            </div>
          </div>
          <div class="us-img">
          <img 
              src={require("../assets/img/Support3.jpg")}
              alt="Support"
            />
          </div>
        </div>
        {/* Termian Nosotros */}

        {/* Inicia valores */}
        <div class="strip">
          <center>
            <h3 class="values">Responsabilidad</h3>
            <h3 class="values">Honestidad</h3>
            <h3 class="values">Pasión</h3>
            <h3 class="values">Entrega</h3>
          </center>
        </div>
        {/* Termina valores */}
        <div class="us-banner">
          <div class="banner-text container">
            <center>
              <h1>Nuestra Vision y Mision</h1>
                <p>
                  Ser la mejor alternativa en brindar servicios de alta calidad, 
                  orientado a cubrir los requerimientos de nuestros clientes desde la 
                  organización hasta la realización del evento, siempre con las propuestas mas innovadoras.
                </p>
            </center>
          </div>
        </div>
        <div class="wave">
          <img
              src={require("../assets/img/Wave2.png")}
              alt="wave"
              height="200px"
              width="auto">
          </img>
          <br/>
        </div>  
        <div class="services" id="services">
          <h1>
            <center>
              <strong>NUESTROS SERVICIOS</strong>
            </center>
            <br/>
            <br/>
          </h1>
          <center>
            <div class="row-services">
              <div class="col-icons">
                <img
                  src={require("../assets/img/services-icons/audio.png")}
                  alt="Audio"
                ></img>
                <h3>Audio</h3>
                <img
                  src={require("../assets/img/services-icons/iluminacion.png")}
                  alt="Iluminacion"
                ></img>
                <h3>Iluminación</h3>
              </div>
              <div class="col-icons">
                <img
                  src={require("../assets/img/services-icons/backline.png")}
                  alt="Backline"
                ></img>
                <h3>Back Line</h3>
                <img
                  src={require("../assets/img/services-icons/microfonia.png")}
                  alt="Microfonia"
                ></img>
                <h3>Microfonia</h3>
              </div>
              <div class="col-icons">
                <img
                  src={require("../assets/img/services-icons/circuito.png")}
                  alt="Circuito Cerrado"
                ></img>
                <h3>Circuito Cerrado</h3>
                <img
                  src={require("../assets/img/services-icons/pantallas.png")}
                  alt="Pantallas"
                ></img>
                <h3>Pantallas</h3>
              </div>
              <div class="col-icons">
                <img
                  src={require("../assets/img/services-icons/groundsupport.png")}
                  alt="Ground Support"
                ></img>
                <h3>Ground Support</h3>
                <img
                  src={require("../assets/img/services-icons/tarimas.png")}
                  alt="Tarimas"
                ></img>
                <h3>Tarimas</h3>
              </div>
            </div>
          </center>
        </div>     
        <div class="wave">
          <img
              src={require("../assets/img/Wave2.png")}
              alt="wave"
              height="200px"
              width="auto">
          </img>
          <br/>
        </div>     
        <div class="strip">
          <center>
            <h3>Cuenta con nosotros en cualquier momento.</h3>
            <h3>
              <strong>No te preocupes en tu evento nosotros te apoyamos</strong>
            </h3>
          </center>
        </div>
      </div>
    );
  }
}

export default Content;
